export const gcp_project_id="production-kainic";
export const gcp_region="us-central1"
export const gcp_cloud_functions = `https://${gcp_region}-${gcp_project_id}.cloudfunctions.net`
export const rest_analytics_version=`${gcp_cloud_functions}/api/v1/`
export const gcp_lake_bucket=`${gcp_project_id}_lake`
export const gcp_uploads_bucket=`${gcp_project_id}_uploads`
export const tracker_dashboard='https://datastudio.google.com/embed/reporting/53028c4f-8692-4bcb-a53d-4f7e74e858cf/page/afFHC'
export const active_sesstion='https://datastudio.google.com/embed/reporting/cd4fd4e3-c985-46fb-a2ed-8f028a3a8b54/page/afFHC'
export const mini_tracker_dashboard='https://datastudio.google.com/embed/reporting/ba5e2d4f-79ea-4077-ab2d-23d0add74358/page/pz0fC'
export const mini_model_dashboard='https://datastudio.google.com/embed/reporting/93ef5aba-4063-4a3e-a698-b18c37ccd780/page/pz0fC'
export const client_coverage_dashboard='https://lookerstudio.google.com/embed/reporting/e2cee051-b333-4583-8b09-8393f769ff92/page/p_tc5qx2hm6c'
export const model_experiment_dashboard='https://datastudio.google.com/embed/reporting/e1d408ef-4e62-496e-b1c4-2cbbbbc792f3/page/NuhcC';
export const recommendation_widget_dashboard='https://lookerstudio.google.com/embed/reporting/7dab1ea0-16ed-4766-a6f7-b0c412443822/page/p_tc5qx2hm6c';
export const billing_dashboard= 'https://lookerstudio.google.com/embed/reporting/b0bab841-4e8b-4084-99e7-030f795e9d1e/page/NuhcC';
export const detailed_billing_dashboard= 'https://lookerstudio.google.com/embed/reporting/45977d55-5a12-42ef-b78e-84cd75ebea74/page/NuhcC'
export const get_shopify_products_container = 'https://get-spf-products-ib44giuana-uc.a.run.app';
export const stripe_public_key= "pk_test_51QHQ40CmLzuwJ77HxQ4iOJ63uVCmhp5Fotjr5mpDj4SBA1z69uwl2ht2JTMeZ9ZRX0ZeKMkVpfndA2vKWVgQbviC00AXfZlNbC";
export const environment = {
  production: true,
  defaultauth: 'firebase',
  firebaseConfig: {
    apiKey: "AIzaSyBv2ZuKC4uk2trBDdvl7brU8Wj7L3hj6hY",
    authDomain: `${gcp_project_id}.firebaseapp.com`,
    projectId: gcp_project_id,
    storageBucket: `${gcp_project_id}.appspot.com`,
    messagingSenderId: "798572529069",
    appId: "1:798572529069:web:566b57b4c57869f5530d24",
    measurementId: "G-C1LB4W7F3M"
  }
};
